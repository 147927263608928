import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import Img from 'next/image';
import { Trans } from '@lingui/react';
import Button from '@components/buttons';
import { Box } from '@components/layout/Grid';
import { Body as body, Modal as modal } from './base';
import Icon from '@components/Icon';
import LanguageContext from '@lib/contexts/languageContext';
import Link from '@components/Link';

const Modal = styled(modal)`
  .MuiDialog-paperFullScreen,
  .MuiDialog-paperFullScreen .ModalWrapper {
    height: auto;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  color: #fff;
  border: none;
  background: none;
  z-index: 1;
  cursor: pointer;
`;

const Body = styled(body)`
  padding: 0;
`;

const Header = styled(Box)`
  position: relative;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(157, 201, 199, 0.8);
    z-index: 1;
  }

  :after {
    content: '\\e948';
    display: block;
    font-family: 'ygb-font';
    font-size: 26px;
    color: #fff;
    text-align: center;
    z-index: 2;
  }

  .CastModal--Img > div {
    width: 100%;
  }
`;

const Content = styled(Box)`
  max-width: 400px;
  text-align: center;
  margin: 0 auto;
`;

const Title = styled.p`
  font-size: 22px;
  font-weight: 700;
  margin-top: 0;
`;

const Text = styled.p`
  font-size: 18px;
`;

declare type Params = {
  open?: boolean;
};

const CastModal = (
  props: Params
): [
  JSX.Element,
  { openModal(): void; closeModal(): void; isOpen: boolean },
] => {
  const { open } = props;
  const [isOpen, setOpen] = useState(open);
  const lang = useContext(LanguageContext);

  const content = (
    <Modal
      open={isOpen}
      data-testid="CastModal"
      handleClose={() => setOpen(false)}
    >
      <Body>
        <Header>
          <Img
            className="CastModal--Img"
            alt=""
            src="/static/cheerful-sportswomen-giving-high-five-to-each-other.jpg"
            layout="fill"
          />
          <CloseButton onClick={() => setOpen(false)}>
            <Icon name="ygb-icon-close" fontSize="18px" />
          </CloseButton>
        </Header>
        <Content py={40}>
          <Title>
            <Trans id="cast_modal.title" />
          </Title>
          <Text>
            <Trans id="cast_modal.subtitle" />
          </Text>
          <Link href={`/${lang}/subscription/select`}>
            <Button appearance="stone" href={`/${lang}/subscription/select`}>
              <Trans id="cast_modal.view_offers" />
            </Button>
          </Link>
        </Content>
      </Body>
    </Modal>
  );

  return [
    content,
    {
      isOpen,
      openModal: () => setOpen(true),
      closeModal: () => setOpen(false),
    },
  ];
};

export default CastModal;
