import React from 'react';
import styled, { css } from 'styled-components';

import NextLink from 'next/link';

export const AHeaderSpan = styled.span<{ selected?: boolean }>`
  color: ${(props) => props.theme.colors.neutrals['black']};
  font-size: 13px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.primary['blue-1']};
  }

  ${(props) =>
    props.selected &&
    css`
      color: ${(props) => props.theme.colors.primary['blue-1']};
    `};
`;

export const AHeader = styled.a<{ selected?: boolean }>`
  color: ${(props) => props.theme.colors.neutrals['black']};
  font-size: 13px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.primary['blue-1']};
  }

  ${(props) =>
    props.selected &&
    css`
      color: ${(props) => props.theme.colors.primary['blue-1']};
    `};
`;

export const A = styled.a<{ bold?: boolean }>`
  color: ${(props) =>
    props.color ? props.color : props.theme.colors.primary['blue-2']};
  font-weight: ${(props) => (props.bold ? '600' : 'normal')};
  font-size: 13px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${(props) =>
      props.color ? props.color : props.theme.colors.primary['blue-1']};
  }
`;

const FooterAnchor = styled(A)`
  &:hover {
    color: ${(props) =>
      props.color ? props.color : props.theme.colors.neutrals['white']};
    font-weight: bold;
    text-decoration: underline;
  }
`;

const Link = (props) => {
  return (
    // <NextLink href={props.href} as={props.asPath}>
    // </NextLink>
    <A href={props.href} bold={props.bold} color={props.color}>
      {props.children}
    </A>
  );
};

export const Anchor = ({ className = '', href, children, ...props }) => (
  <NextLink
    passHref
    href={href}
    className={className}
    target={props.target}
    style={{ textDecoration: 'none', ...props.style }}
    {...props}
  >
    {children}
  </NextLink>
);

// created FooterLink/FooterAnchor because not sure if the Link is used outside of the footer
export const FooterLink = (props) => {
  return (
    <FooterAnchor href={props.href} bold={props.bold} color={props.color}>
      {props.children}
    </FooterAnchor>
  );
};

export default Link;
