import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled, { useTheme } from 'styled-components';
import { t } from '@lingui/macro';
import * as Sentry from '@sentry/nextjs';
import once from 'lodash/once';
import VideoCoverLocked from './VideoCoverLocked';
import useFlags from '@lib/hooks/useFlags';
import { UserContext } from '@lib/contexts/UserProvider';
import useCastModal from '@organisms/modals/useCastModal';
import useLoginModal from '@lib/hooks/useLoginModal';
import AnalyticsManager from '@lib/analytics/manager';
import { CastLoader } from '@lib/react-cast-sender/utils/CastLoader';
import { Video } from '@gql/generated';
import { useVideoStats } from '@lib/hooks/useVideoStats';
import pckg from '../../../package.json';
import videojs from 'video.js';
import { isSafariOnMacOS, supportsHLS } from '@lib/utils';

const VideoWrapper = styled.div<{ thumbnailImage: string }>`
  position: relative;
  display: flex;
  flex-direction: column-reverse;

  .video__placeholder {
    display: none;
  }

  .VideoPlayer--Container:empty + .video__placeholder {
    position: relative;
    display: block;
    width: 100%;
    padding-top: 56%;
    background-image: url(${({ thumbnailImage }) => thumbnailImage});
    background-size: cover;

    .vjs-big-play-button {
      display: block;
    }

    .VideoPlayer--Container {
      display: flex;
    }
  }

  .video-js.vjs-has-started + .limitedAccessMsg {
    display: block;
  }

  .limitedAccessMsg {
    display: none;
    width: 100%;

    .vjs-overlay-preview {
      display: block;
      width: 100%;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      text-align: left;
      padding: 10px;
      white-space: nowrap;
      background-color: #667c91;
    }
  }
`;

export const Tags = styled.div`
  .vjs-has-started & {
    display: none;
  }

  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
`;

export const Tag = styled.span`
  color: white;
  font-size: 11px;
  text-transform: uppercase;
  border-radius: 3px;
  padding: 3px 5px;
  background-color: #88bfbd;
`;

// const TRIAL_DURATION = 60.010667;

function getVideoSource(hlsDisabled, video) {
  if (hlsDisabled) {
    return [...video.player.current.assets]
      .sort((a, b) => b.resolution - a.resolution)
      .map((source) => {
        if (!source.src) {
          return null;
        }
        return {
          src: source.src,
          type: 'video/mp4',
          label: `${source.resolution}p`,
          res: source.resolution,
        };
      });
  } else {
    return [{ src: video.hls, type: 'application/x-mpegURL' }];
  }
}

function getVideoConfig({ hlsDisabled, video, currentUser }) {
  let mux = null;
  if (currentUser?.isNoneTrialPremium) {
    mux = {
      debug: false,
      data: {
        env_key: process.env.MUX_TOKEN,
        // Player Metadata
        player_name: 'video.js',
        player_version: pckg.dependencies['video.js'],
        player_init_time: Date.now(),
        // Video Metadata
        video_id: video.identifier,
        video_title: video?.content?.title,
        video_series: '',
        video_duration: video?.minutes * 60 * 1000, // in milliseconds, ex: 120000
        // user
        user_id: currentUser?.id,
      },
    };
  }
  if (hlsDisabled) {
    return {
      plugins: {
        mux,
        videoJsResolutionSwitcher: {
          default: video.kind === 'audio' ? 360 : 720,
          dynamicLabel: false,
        },
      },
    };
  } else {
    const overrideNative = false;
    const config =
      supportsHLS() && isSafariOnMacOS()
        ? {
            html5: {
              hls: {
                overrideNative: overrideNative,
              },
              vhs: {
                overrideNative: overrideNative,
              },
              nativeVideoTracks: overrideNative,
              nativeAudioTracks: overrideNative,
              nativeTextTracks: overrideNative,
            },
          }
        : {};

    console.log('config', config);
    return {
      plugins: {
        mux,
      },
      ...config,
    };
  }
}

type Config = {
  video: Video;
  isAirPlayDevice: boolean;
};
const createVideoElement = (
  container: HTMLElement,
  config: Config
): HTMLVideoElement => {
  const { video, isAirPlayDevice } = config;
  const videoEl = document.createElement('video');
  videoEl.src = isAirPlayDevice ? video.hls : '';
  videoEl.muted = false;
  //
  videoEl.classList.add('video-js', 'vjs-default-skin', 'vjs-16-9');
  if (video.kind === 'audio') {
    videoEl.classList.add('vjs-audio');
  }
  //

  container.innerHTML = '';
  container.appendChild(videoEl);
  return videoEl;
};

// const applicationId = '3552EF75'; // monssef@yogobe.com
const applicationId = '87B0FFF1'; // new app id
let _castLoaded = false;
const VideoPlayer = (props) => {
  const player = useRef<ReturnType<typeof videojs> | null>(null);
  const videoContainerRef = useRef(null);
  let videoElementID = props.elementID || 'video_element';
  if (props?.video.identifier) {
    videoElementID = `_${props?.video.identifier}`;
  }
  const [flags] = useFlags();
  const userRef = useRef(null);
  const theme = useTheme();
  const [currentUser] = useContext(UserContext);
  const [showLimitBanner, setShowLimitBanner] = useState(false);
  const [errored, setErrored] = useState({});
  const trackVideo = useVideoStats({
    videoId: props?.video.id,
    duration: props?.video?.meta?.minutes,
  });

  const [castModal, { openModal: openCastModal }] = useCastModal({
    open: false,
  });
  const [loginModal, { openModal: openLoginModal }] = useLoginModal({
    open: false,
  });

  const onPlay = (video) => {
    props.onPlay ? props.onPlay(video) : null;

    if (video?.currentTime?.() === 0) {
      props.onPlayStart?.(video);
    }
  };

  const onPause = (video) => {
    props.onPause ? props.onPause(video) : null;
  };

  const addClassToControlBar = (classname) => {
    const controlBar = document.querySelector('.vjs-control-bar');
    if (controlBar) {
      controlBar.classList.add(classname);
    }
  };

  const getCastMediaParams = () => {
    const assets = [...props.video.player.assets].reduce((acc, asset) => {
      acc[asset.resolution] = asset;
      return acc;
    }, {});

    // let mediaSrc, mediaType;
    // we set mp4 playlist as default
    const mediaSrc = assets['720'].src || assets['1080'].src;
    const mediaType = 'video/mp4';

    return { id: mediaSrc, src: mediaSrc, type: mediaType };
  };

  useEffect(() => {
    // used ref for canCast, because closure of canCast keeps old value in currentUser
    userRef.current = currentUser;
  }, [currentUser]);

  function canCast() {
    if (
      !props.isTrailer &&
      (props.video.hasAccess || userRef.current?.isPremium)
    ) {
      return true;
    } else if (userRef.current?.isPremium === false) {
      openCastModal();
    } else {
      openLoginModal();
    }

    return false;
  }

  const isAirPlayDevice = useMemo(() => {
    return typeof window !== 'undefined'
      ? !!window?.WebKitPlaybackTargetAvailabilityEvent
      : false;
  }, []);

  const setupVideoPlayer = useCallback(
    once(async () => {
      const { video, ...otherProps } = props;
      if (videoContainerRef.current) {
        const videoElement = createVideoElement(videoContainerRef.current, {
          video,
          isAirPlayDevice,
        });
        //

        // if (!videojs.getPlugin('airPlay')) {
        //   require('@silvermine/videojs-airplay')(videojs);
        // }
        if (!videojs.getPlugin('chromecast')) {
          require('@silvermine/videojs-chromecast')(videojs);
        }
        if (!videojs.getPlugin('nuevo')) {
          import('./plugins/nuevo.js');
        }

        Promise.all([
          (await import('./plugins/vjsAirplayButton')).default(videojs),
          (await import('./plugins/overlay-plugin')).default(videojs),
          (await import('./plugins/vjsCustomButton')).default(videojs),
          await import('videojs-mux'),
        ]);

        player.current = videojs(
          videoElement,
          {
            ...otherProps,
            sources: getVideoSource(flags?.disable_hls, video),
            poster: thumbnailImage,
            aspectRatio: '16:9',
            controls: false,
            autoplay: false,
            controlBar: {
              playToggle: true,
              volumePanel: {
                // inline: false,
                // vertical: true,
                mouseVolumeLevelDisplay: true,
                horizontal: true,
              },
              currentTimeDisplay: true,
              timeDivider: true,
              durationDisplay: true,
              remainingTimeDisplay: true,
              fullscreenToggle: true,
              subtitlesButton: false,
            },
            // playbackRates: [0.5, 1, 1.5, 2],
            defaultPlaybackRate: 1,
            ...getVideoConfig({
              hlsDisabled: flags?.disable_hls,
              video: video,
              currentUser,
            }),
            techOrder: ['chromecast', 'html5'], // Required
            // Configuration for the Chromecast Tech
            chromecast: {
              source: getCastMediaParams(),
              requestTitleFn: function () {
                return video.content.title;
              },
              requestSubtitleFn: function () {
                const deviceName = player.current['chromecastSessionManager']
                  .getCastContext()
                  .getCurrentSession()
                  .getCastDevice().friendlyName;
                return `Casting to ${deviceName}`;
              },
              requestCastSubtitleFn() {
                return ' ';
              },
              requestCastTitleFn: function () {
                return video.content.title;
              },
            },
          },
          function onPlayerReady() {
            // @ts-ignore
            player.current.nuevo({
              shareMenu: false,
              zoomMenu: false,
              buttonRewind: false,
              buttonForward: false,
              settingsButton: false,
              pipButton: false,
            });

            player.current.src(getVideoSource(flags?.disable_hls, video));
            // if ((video?.meta?.minutes * 60) / video.cursorAt > 0.95) {
            //   player.current.currentTime(video.cursorAt);
            // }

            player.current
              .getChild('controlBar')
              .addChild('spacer')
              .addClass('vjs-mobile-center-spacer');

            // @ts-ignore
            if (!props.isTrailer) {
              player.current?.chromecast({
                receiverAppID: applicationId,
                buttonPositionIndex: 10,
                canCast,
              });
            }
            // for some reason `subtitlesButton` in config is not working
            // @ts-ignore
            player.current?.controlBar.subsCapsButton?.disable?.();
            //
            const limitedAccessMsg = document.querySelector(
              `.${videoElementID}--limitedAccessMsg`
            );
            this.on('play', () => {
              onPlay(this);
            });

            this.on('pause', () => {
              onPause(this);
            });

            this.on('click', (e) => {
              try {
                if (!this.hasStarted()) {
                  this.play();
                } else if (
                  // mobile view
                  e.target.matches('.vjs-control-bar') ||
                  // desktop view
                  (e.target.matches('video') && this.hasStarted())
                ) {
                  if (this.paused()) {
                    this.play();
                  } else {
                    this.pause();
                  }
                }
              } catch (ex) {
                //
              }
            });

            this.on('loadedmetadata', function () {
              const duration = this.duration();
              const overlays = [];
              if (props.isLimited) {
                addClassToControlBar('vjs-overlayed');
                limitedAccessMsg.innerHTML = `<div class="vjs-overlay-preview"><i class="ygb-icon-infob"></i> ${t`video.preview_msg`}</div>`;
                overlays.push({
                  content: `<i class="ygb-icon-infob"></i> ${t`video.preview_msg`}`,
                  start: 'play',
                  end: Math.floor(duration - 6),
                  class: 'vjs-overlay-preview',
                });
              }

              this.overlay({
                content: 'overlay content',
                debug: false,
                overlays: overlays,
              });
            });

            this.on('timeupdate', (e) => {
              if (!currentUser?.impersonated && currentUser?.id) {
                trackVideo(this.currentTime());
              }

              if (!props.isPlayList) {
                if (typeof props.onTimeUpdate === 'function') {
                  props.onTimeUpdate(this.duration(), this.currentTime?.());
                }
              }
            });

            this.on('error', function (error) {
              Sentry.captureException(error, {
                contexts: {
                  video: {
                    id: props?.video?.id,
                    hsl: props?.video?.hls,
                  },
                },
              });
              const newErrored = errored;
              let resolution;
              if (flags?.disable_hls) {
                resolution = this.currentResolutionState.label;
              } else {
                // @ts-ignore
                const index = player.current.qualityLevels().selectedIndex;
                // @ts-ignore
                resolution = player.current.qualityLevels(index)?.height;
              }
              if (newErrored[resolution]) {
                newErrored[resolution] = 1;
              } else {
                newErrored[resolution]++;
              }
              setErrored(newErrored);
              if (newErrored[resolution] <= 2) {
                videoElement.load();
              }
            });

            this.on('ended', function () {
              setShowLimitBanner(true);
              AnalyticsManager()._PIXEL_TRACK('Video Completion', true);
              if (props.onEnd) {
                props.onEnd();
              }
              if (window.fullScreen) {
                if (document.exitFullscreen) {
                  document.exitFullscreen();
                } else if (document.mozCancelFullScreen) {
                  document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) {
                  document.webkitExitFullscreen();
                }
              }
            });
            this.on('dblclick', function () {
              if (
                document.fullscreenElement /* Standard syntax */ ||
                document.webkitFullscreenElement /* Chrome, Safari and Opera syntax */ ||
                document.mozFullScreenElement /* Firefox syntax */ ||
                document.msFullscreenElement /* IE/Edge syntax */
              ) {
                if (document.exitFullscreen) {
                  document.exitFullscreen();
                } else if (document.mozCancelFullScreen) {
                  /* Firefox */
                  document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) {
                  /* Chrome, Safari and Opera */
                  document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) {
                  /* IE/Edge */
                  document.msExitFullscreen();
                }
              } else {
                this.requestFullscreen();
              }
            });
          }
        );
        if (window.WebKitPlaybackTargetAvailabilityEvent) {
          videoElement.addEventListener(
            'webkitplaybacktargetavailabilitychanged',
            (event) => {
              // @ts-ignore
              switch (event.availability) {
                case 'available':
                  try {
                    const child = player.current
                      ?.getChild('controlBar')
                      ?.getChild('VjsAirplayButton');
                    if (!child) {
                      player.current
                        ?.getChild('controlBar')
                        ?.addChild('VjsAirplayButton');
                    }
                  } catch (err) {
                    console.error(err);
                  }
                  break;
                case 'not-available':
                  player.current
                    ?.getChild('controlBar')
                    // @ts-ignore
                    ?.removeChild('VjsAirplayButton');
                  break;
              }
            }
          );
        }
        if (props.isPlayList) {
          player.current.addClass('video-playlist');
          player.current.getChild('controlBar').addChild('VjsCustomButton', {
            type: 'next',
            next: () => {
              props.playNextVideo();
            },
          });
          player.current.getChild('controlBar').addChild('VjsCustomButton', {
            type: 'prev',
            prev: () => {
              props.playPrevVideo();
            },
          });
        }

        const togglePlayer = (e) => {
          // if space is clicked
          // we check that event is not triggered on input button and anchor
          // because those should will handle the event instead
          if (
            e.key === ' ' &&
            ![
              'HTMLInputElement',
              'HTMLButtonElement',
              'HTMLAnchorElement',
              'HTMLTextAreaElement',
            ].includes(e.target.constructor.name)
          ) {
            e.preventDefault();
            try {
              if (player.current?.id() !== props?.video?.id) {
                player.current?.dispose();
              } else if (player.current.paused()) {
                player.current.play();
              } else {
                player.current.pause();
              }
            } catch (ex) {
              //
            }
          }
        };

        document.addEventListener('keydown', togglePlayer);

        return () => {
          if (player?.current) {
            try {
              player.current.dispose();
              player.current = null;
            } catch (ex) {
              // idk why the above crashes
            }
          }

          document.removeEventListener('keydown', togglePlayer);
        };
      }
    }),
    [videoElementID]
  );

  useEffect(() => {
    if (!_castLoaded) {
      CastLoader.load()
        .then(() => (_castLoaded = true))
        .catch(() => {
          //
        });
    }

    try {
      if (player.current) {
        player.current.dispose?.();
        player.current = null;
      }
      setupVideoPlayer();
    } catch (error) {
      Sentry.captureException(error);
    }

    // prevent safari from adding controls attribute to video
    let observer = null;
    if (videoContainerRef.current) {
      observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          if (
            mutation.type == 'attributes' &&
            mutation.attributeName === 'controls'
          ) {
            const video = videoContainerRef.current.querySelector('video');
            video.removeAttribute('controls');
          }
        });
      });

      observer.observe(videoContainerRef.current, {
        attributes: true,
      });
    }
    //

    return () => {
      if (player.current) {
        player.current.dispose?.();
        player.current = null;
      }
      observer?.disconnect();
    };
  }, []);

  const { thumbnailImage, isLimited, tags = [], video, ...rest } = props;

  const LockedOverlay = rest.videoEndOverlay || VideoCoverLocked;

  return (
    <Sentry.ErrorBoundary onError={console.error}>
      {showLimitBanner && isLimited ? (
        <LockedOverlay />
      ) : (
        <VideoWrapper
          id={videoElementID}
          className={rest.className}
          thumbnailImage={thumbnailImage}
        >
          {tags?.length > 0 ? (
            <Tags data-testid="VideoPlayer--Tags">
              {tags.map((tag) => (
                <Tag className="videoplayer-tag" key={tag}>
                  {tag}
                </Tag>
              ))}
            </Tags>
          ) : null}
          <div
            // need to keep this unique for when there's multiple video players on the page
            className={`${videoElementID}--limitedAccessMsg limitedAccessMsg`}
          />
          <div
            className="VideoPlayer--Container aspect-video"
            ref={videoContainerRef}
          >
            <video
              className="size-full"
              poster={thumbnailImage}
              src={video.hls}
            ></video>
          </div>
        </VideoWrapper>
      )}
      {castModal}
      {loginModal}
    </Sentry.ErrorBoundary>
  );
};

export default VideoPlayer;
